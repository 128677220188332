import { colors, fonts, styles } from "./styles";

import { extendTheme } from "@chakra-ui/react";
import Button from "./components/button";
import Input from "./components/input";

const candi_theme = extendTheme({
  colors,
  fonts,
  styles,
  components: {
    Input,
    Button,
  },
});

export default extendTheme(candi_theme);
