import axios, { AxiosResponse } from "axios";

import { ENV } from "../constants/environment.base";
import { PublicPetOrdersInput } from "../types/db.schema";
import { IPetOrder } from "../types/types";

export class PetOrderService {
  private readonly API_ROUTE_PREFIX: string = "pet-order";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ADMIN_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ADMIN_ENDPOINT;

  /**
   * List all pet orders
   *
   * @returns Promise with pet orders response
   */
  public list: (options?: {
    expand?: Array<string>;
  }) => Promise<AxiosResponse<IPetOrder[]>> = (options) => {
    return axios.get(`${this.API_PREFIX}/list`, {
      params: { ...options },
    });
  };

  /**
   * Pet order detail
   *
   * @returns Promise with pet order response
   */
  public retrieve: (
    id: string,
    options?: {
      expand?: Array<string>;
    }
  ) => Promise<AxiosResponse<IPetOrder>> = (id, options) => {
    return axios.get(`${this.API_PREFIX}/${id}`, {
      params: { ...options },
    });
  };

  /**
   * Pet order update
   *
   * @returns Promise with updated pet order response
   */
  public update: (
    id: string,
    data: Partial<PublicPetOrdersInput>,
    options?: {
      expand?: Array<string>;
    }
  ) => Promise<AxiosResponse<IPetOrder>> = (id, data, options) => {
    return axios.put(`${this.API_PREFIX}/${id}`, data, {
      params: { ...options },
    });
  };
}
