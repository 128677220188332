import { Box, HStack, Icon, Link, SlideFade, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IPet, IUser } from "../types/types";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { Helmet } from "react-helmet";
import { FaUserSlash } from "react-icons/fa";
import { NavLink as RouterNavLink } from "react-router-dom";
import Stripe from "stripe";
import { DataTable } from "../components/DataTable";
import Spinner from "../components/Spinner";
import { ENV } from "../constants/environment.base";
import { PetService } from "../services/pet.service";

const columnHelper = createColumnHelper<IPet>();

const columns = [
  columnHelper.accessor("_id", {
    cell: (info) => info.getValue(),
    header: "ID",
  }),
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Nombre",
  }),
  columnHelper.accessor("user", {
    cell: (info) =>
      ((info.getValue() as IUser).stripe as Stripe.Customer).deleted ? (
        <HStack>
          <Icon as={FaUserSlash} color={"red.500"} />
          <Text color={"red.500"}>No encontrado</Text>
        </HStack>
      ) : (
        <Link
          isExternal
          as={RouterNavLink}
          to={`https://dashboard.stripe.com/customers/${
            ((info.getValue() as IUser).stripe as Stripe.Customer).id
          }`}
          color={"blue.700"}
          fontWeight={"medium"}
        >
          {((info.getValue() as IUser).stripe as Stripe.Customer).name ||
            ((info.getValue() as IUser).stripe as Stripe.Customer).email}
          <ExternalLinkIcon mx="2px" />
        </Link>
      ),
    header: "Usuario",
  }),
  columnHelper.accessor("weight", {
    cell: (info) => info.getValue(),
    header: "Peso",
  }),
  columnHelper.accessor("age_group", {
    cell: (info) => info.getValue(),
    header: "Edad",
  }),
  columnHelper.accessor("activity_level", {
    cell: (info) => info.getValue(),
    header: "Actividad",
  }),
  columnHelper.accessor("breed_code", {
    cell: (info) =>
      ENV.DOG_BREEDS[
        ENV.DOG_BREEDS.findIndex((b) => b.code === info.getValue())
      ].name,
    header: "Raza",
  }),
  columnHelper.accessor("gender", {
    cell: (info) => info.getValue(),
    header: "Género",
  }),
  columnHelper.accessor("illness", {
    cell: (info) => (info.getValue() ? "Sí" : "No"),
    header: "Enfermedades",
  }),
  columnHelper.accessor("sterilized", {
    cell: (info) => (info.getValue() ? "Sí" : "No"),
    header: "Esterilizado",
  }),
  columnHelper.accessor("daily_grams", {
    cell: (info) => info.getValue(),
    header: "Gramos de ingesta",
  }),
];

const petService = new PetService();

const Pets = () => {
  const [pets, setPets] = useState<IPet[]>([]);
  const [petsLoading, setPetsLoading] = useState<boolean>(false);

  useEffect(() => {
    getPets();
  }, []);

  const getPets = async () => {
    setPetsLoading(true);

    petService
      .list({ expand: ["user"] })
      .then(({ data }) => {
        console.log(data);
        setPets(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setPetsLoading(false));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peludos - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box minH="100vh">
        {petsLoading ? (
          <Spinner />
        ) : (
          <SlideFade
            in={!petsLoading}
            style={{ width: "100%" }}
            offsetY={"20px"}
            transition={{ enter: { duration: 0.5 } }}
          >
            <DataTable
              columns={columns}
              data={pets}
              rowDetailPath={"pets"}
              rowDetailIdentifier={"_id"}
              defaultColumnVisibility={{
                _id: false,
              }}
            />
          </SlideFade>
        )}
      </Box>
    </>
  );
};
export default Pets;
