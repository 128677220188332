import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HiChevronRight, HiXMark } from "react-icons/hi2";

import React from "react";
import { Link } from "react-router-dom";
import { images } from "../assets/images/images";
import { useResetPassword } from "../hooks/useResetPassword";

const ResetPassword: React.FC = () => {
  const {
    email,
    setEmail,
    emailSent,
    loading,
    errorMsg,
    handlePwdResetRequest,
  } = useResetPassword();

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      alignItems={"center"}
      justifyContent={"center"}
      m={"0 auto"}
      backgroundImage={`url(${images.auth_bg_pattern})`}
    >
      <SlideFade
        in={true}
        style={{ width: "100%" }}
        offsetY={"50px"}
        transition={{ enter: { duration: 0.5 } }}
      >
        <VStack
          spacing={8}
          w={"full"}
          maxW={"lg"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          m={"0 auto"}
          bgColor={"white"}
          p={8}
          borderRadius={"2xl"}
          borderWidth={2.5}
          borderColor={"brand.500"}
        >
          <VStack
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            spacing={4}
          >
            <Heading as="h1" fontWeight={"medium"} size="lg">
              Vuelve a la{" "}
              <Text as="span" color={"brand.500"} fontWeight={"semibold"}>
                #candifam
              </Text>
            </Heading>

            <Text fontWeight={"medium"}>
              Recupera tu contraseña introduciendo el correo electrónico con el
              que te registraste.
            </Text>
          </VStack>

          {!emailSent ? (
            <VStack
              spacing={8}
              as={"form"}
              w={"full"}
              alignItems={"flex-start"}
              onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
                e.preventDefault();
                handlePwdResetRequest();
              }}
            >
              <VStack w={"full"} alignItems={"flex-start"} spacing={3}>
                <FormControl id="email" isRequired>
                  <FormLabel fontWeight={"medium"}>
                    Correo electrónico
                  </FormLabel>
                  <Input
                    type="email"
                    size={"lg"}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <FormHelperText>
                    Te enviaremos un correo electrónico para cambiar tu
                    contraseña.
                  </FormHelperText>
                </FormControl>
              </VStack>

              <Button
                w={"full"}
                borderRadius={"2xl"}
                justifyContent={"space-between"}
                size={"lg"}
                colorScheme="brand"
                type="submit"
                rightIcon={<Icon as={HiChevronRight} />}
                isLoading={loading}
              >
                Cambiar contraseña
              </Button>
            </VStack>
          ) : (
            <Alert status="success" borderRadius={"2xl"}>
              <AlertIcon alignSelf={"flex-start"} />
              <Text fontWeight={"medium"}>
                ¡Mira el correo! (Esperemos que no haga falta, pero spam
                también...)
              </Text>
            </Alert>
          )}

          <Collapse in={!!errorMsg}>
            <HStack alignItems={"flex-start"}>
              <Icon
                as={HiXMark}
                color={"red.500"}
                strokeWidth={1}
                h={5}
                w={5}
                mt={"0.5"}
              />
              <Text color={"red.500"}>{errorMsg}</Text>
            </HStack>
          </Collapse>

          <Text fontWeight={"medium"} size="sm">
            ¿Quieres iniciar sesión?{" "}
            <Link to={"/auth"}>
              <Box as={"span"} fontWeight={"medium"} color={"brand.600"}>
                ¡Haz click aquí!
              </Box>
            </Link>
          </Text>
        </VStack>
      </SlideFade>
    </Flex>
  );
};

export default ResetPassword;
