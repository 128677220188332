import { Alert, AlertIcon, Text, VStack } from "@chakra-ui/react";

const KitchenBilling = () => {
  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={16}
    >
      <Alert status="warning">
        <AlertIcon />
        <Text>
          Esta página está en construcción. Por favor, vuelve más tarde.
        </Text>
      </Alert>
    </VStack>
  );
};

export default KitchenBilling;
