import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";

interface IPriorityBarProps {
  unit?: string;
  data: {
    high: {
      color?: string;
      value: number;
    };
    medium: {
      color?: string;
      value: number;
    };
    low: {
      color?: string;
      value: number;
    };
    rest: {
      color?: string;
      value: number;
    };
  };
  valuesFormatter?: (value: number) => string;
}

const PriorityBar: React.FC<IPriorityBarProps> = ({
  unit,
  data: { low, medium, high, rest },
  valuesFormatter,
}) => {
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    setTotal(
      (low.value || 0) +
        (medium.value || 0) +
        (high.value || 0) +
        (rest.value || 0)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [low, medium, high, rest]);

  if (!total) return null;

  return (
    <HStack w={"full"} spacing={0.5}>
      {high.value > 0 && (
        <VStack w={`${(high.value / total) * 100}%`} justifyContent={"center"}>
          <Text fontWeight={"medium"} fontSize={"14px"}>
            Hoy
          </Text>
          <Flex
            p={4}
            backgroundColor={high.color || "red.500"}
            w={"full"}
            h={"32px"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Text
              as="span"
              fontWeight={"semibold"}
              fontSize={"14px"}
              color={"white"}
            >
              {valuesFormatter ? valuesFormatter(high.value) : high.value}{" "}
              {unit}
            </Text>
          </Flex>
        </VStack>
      )}
      {medium.value > 0 && (
        <VStack
          w={`${(medium.value / total) * 100}%`}
          justifyContent={"center"}
        >
          <Text fontWeight={"medium"} fontSize={"14px"}>
            mañana
          </Text>
          <Flex
            p={4}
            backgroundColor={medium.color || "yellow.500"}
            w={"full"}
            h={"32px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              as="span"
              fontWeight={"semibold"}
              fontSize={"14px"}
              color={"white"}
            >
              {valuesFormatter ? valuesFormatter(medium.value) : medium.value}{" "}
              {unit}
            </Text>
          </Flex>
        </VStack>
      )}
      {low.value > 0 && (
        <VStack w={`${(low.value / total) * 100}%`} justifyContent={"center"}>
          <Text fontWeight={"medium"} fontSize={"14px"}>
            2 días
          </Text>
          <Flex
            p={4}
            backgroundColor={low.color || "green.500"}
            w={"full"}
            h={"32px"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Text
              as="span"
              fontWeight={"semibold"}
              fontSize={"14px"}
              color={"white"}
            >
              {valuesFormatter ? valuesFormatter(low.value) : low.value} {unit}
            </Text>
          </Flex>
        </VStack>
      )}
      {rest.value > 0 && (
        <VStack w={`${(rest.value / total) * 100}%`} justifyContent={"center"}>
          <Text fontWeight={"medium"} fontSize={"14px"}>
            3 días o más
          </Text>

          <Flex
            p={4}
            backgroundColor={high.color || "gray.500"}
            w={"full"}
            h={"32px"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Text
              as="span"
              fontWeight={"semibold"}
              fontSize={"14px"}
              color={"white"}
            >
              {valuesFormatter ? valuesFormatter(rest.value) : rest.value}{" "}
              {unit}
            </Text>
          </Flex>
        </VStack>
      )}
    </HStack>
  );
};

export default PriorityBar;
