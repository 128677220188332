import {
  Button,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { IPriority } from "../hooks/useKitchen";
import PriorityBar from "./PriorityBar";

interface MenuPackingVizProps {
  stock_key_prefix: string;
  heading: {
    text: string;
    icon?: string;
  };
  toPack: {
    [grams: string]: IPriority;
  };
  packed: { [grams: string]: number };
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const MenuPackingViz: React.FC<MenuPackingVizProps> = ({
  stock_key_prefix,
  heading,
  toPack,
  packed,
  updateStock,
}) => {
  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={4}
    >
      <HStack alignItems={"center"} spacing={2}>
        {heading.icon && <Image src={heading.icon} h={8} />}
        <Heading
          as={"h2"}
          size={"lg"}
          fontWeight={"semibold"}
          lineHeight={1}
          mb={0}
        >
          {heading.text}
        </Heading>
      </HStack>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} w={"full"}>
        {Object.keys(toPack).map((grams) => (
          <MenuPackingVizItem
            key={grams}
            grams={grams}
            stock_key_prefix={`${stock_key_prefix}_${grams}`}
            toPack={toPack[grams]}
            packed={packed[grams] || 0}
            updateStock={updateStock}
          />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default MenuPackingViz;

interface MenuPackingVizItemProps {
  grams: string;
  stock_key_prefix: string;
  toPack: IPriority;
  packed: number;
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const MenuPackingVizItem: React.FC<MenuPackingVizItemProps> = ({
  grams,
  stock_key_prefix,
  toPack,
  packed,
  updateStock,
}) => {
  const {
    isOpen: isBagsUpdateOpen,
    onToggle: onBagsUpdateToggle,
    onClose: onBagsUpdateClose,
  } = useDisclosure();

  const [bagsUpdateInput, setBagsUpdateInput] = useState<number>();

  console.log("grams", grams);
  console.log("toPack", toPack);
  console.log("packed", packed);

  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={4}
      bg={useColorModeValue("gray.50", "gray.900")}
      p={4}
      borderRadius={"xl"}
    >
      <Text fontSize={"16px"} fontWeight={"semibold"}>
        {grams} gramos
      </Text>
      <VStack
        w={"full"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={4}
      >
        <HStack
          w={"full"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Text
            fontSize={"3xl"}
            fontWeight={"extrabold"}
            fontFamily={"mono"}
            letterSpacing={"tight"}
          >
            {Math.trunc(packed)}{" "}
            <Text as="span" fontSize={"xl"} fontWeight={"medium"}>
              / {toPack.total} bolsas
            </Text>
          </Text>
          <Popover
            returnFocusOnClose={false}
            isOpen={isBagsUpdateOpen}
            onClose={onBagsUpdateClose}
            placement="right"
          >
            <PopoverTrigger>
              <IconButton
                aria-label="actualizar envasado"
                icon={<AddIcon />}
                colorScheme="brand"
                borderRadius={"lg"}
                onClick={onBagsUpdateToggle}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontSize={"md"} fontWeight={"bold"} pr={10}>
                ¿Cuántos paquetes has envasado?
              </PopoverHeader>
              <PopoverBody>
                <FormControl>
                  <Input
                    type="number"
                    value={bagsUpdateInput}
                    onChange={(e) => {
                      setBagsUpdateInput(parseFloat(e.currentTarget.value));
                    }}
                  />
                  <Button
                    type="submit"
                    size={"sm"}
                    w={"full"}
                    colorScheme="brand"
                    borderRadius={"lg"}
                    mt={4}
                    disabled={!bagsUpdateInput}
                    onClick={() => {
                      updateStock(
                        stock_key_prefix,
                        bagsUpdateInput!,
                        "packing",
                        "packed"
                      );

                      setBagsUpdateInput(0);
                      onBagsUpdateClose();
                    }}
                  >
                    Enviar
                  </Button>
                </FormControl>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </VStack>
      <VStack
        w={"full"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={0}
        flex={1}
      >
        {/* ########################## */}
        {/* TOTAL BAGS */}
        {/* ########################## */}
        {toPack.total - packed > 0 && (
          <Text fontWeight={"semibold"} fontSize={"16px"}>
            Te quedan {toPack.total - packed} bolsitas por envasar
          </Text>
        )}

        {/* ########################## */}
        {/* TOMORROW GRAMS */}
        {/* ########################## */}
        {toPack.high && (toPack.high || 0) - packed > 0 && (
          <Text fontWeight={"bold"} color={"red.500"}>
            Tienes pendientes{" "}
            {Math.min(toPack.high || 0, (toPack.high || 0) - packed)} bolsitas
            para hoy
          </Text>
        )}

        {/* ########################## */}
        {/* 2 DAYS FROM TODAY GRAMS */}
        {/* ########################## */}
        {toPack.medium &&
          (toPack.high || 0) + (toPack.medium || 0) - packed > 0 && (
            <Text fontWeight={"bold"} color={"yellow.500"}>
              Tienes pendientes{" "}
              {Math.min(
                toPack.medium || 0,
                (toPack.high || 0) + (toPack.medium || 0) - packed
              )}{" "}
              bolsitas para mañana
            </Text>
          )}

        {/* ########################## */}
        {/* 3 DAYS FROM TODAY GRAMS */}
        {/* ########################## */}
        {toPack.low &&
          (toPack.high || 0) +
            (toPack.medium || 0) +
            (toPack.low || 0) -
            packed >
            0 && (
            <Text fontWeight={"bold"} color={"green.500"}>
              Tienes pendientes{" "}
              {Math.min(
                toPack.low || 0,
                (toPack.high || 0) +
                  (toPack.medium || 0) +
                  (toPack.low || 0) -
                  packed
              )}{" "}
              bolsitas para dentro de 2 días
            </Text>
          )}
      </VStack>
      <VStack
        w={"full"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        {/* ########################## */}
        {/* PRIORITY BAR */}
        {/* ########################## */}
        <PriorityBar
          // unit="bolsas"
          data={{
            high: { value: toPack.high },
            medium: { value: toPack.medium },
            low: { value: toPack.low },
            rest: { value: toPack.rest },
          }}
        />

        {/* ########################## */}
        {/* PROGRESS BAR */}
        {/* ########################## */}
        <Progress
          w={"full"}
          colorScheme="green"
          size="md"
          value={(packed / toPack.total) * 100}
          borderRadius={8}
        />
        <Text
          fontFamily={"mono"}
          fontSize={"sm"}
          fontWeight={"medium"}
          lineHeight={"shorter"}
        >
          {((packed / toPack.total) * 100).toFixed(2)}% envasado del total
        </Text>
      </VStack>
    </VStack>
  );
};
