import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { Select as ChakraSelect } from "chakra-react-select";
import { Helmet } from "react-helmet";
import { DataTable } from "../components/DataTable";
import Spinner from "../components/Spinner";
import { AdminService } from "../services/admin.service";
import { IStockItem } from "../types/types";

const columnHelper = createColumnHelper<IStockItem>();

const columns = [
  columnHelper.accessor("item", {
    cell: (info) => info.getValue(),
    header: "Item",
  }),
  columnHelper.accessor("stock", {
    cell: (info) => info.getValue(),
    header: "Stock",
  }),
];

const adminService = new AdminService();

const Suppliers = () => {
  const toast = useToast();

  const {
    isOpen: isStockUpdateModalOpen,
    onOpen: onStockUpdateModalOpen,
    onClose: onStockUpdateModalClose,
  } = useDisclosure();

  const [loading, setLoading] = useState<boolean>(false);
  const [stock, setStock] = useState<IStockItem[]>();

  const [updateStockData, setUpdateStockData] = useState<{
    item: string;
    stock: number;
  }>({ item: "", stock: 0 });

  useEffect(() => {
    getStock();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStock: () => Promise<void> = async () => {
    setLoading(true);

    try {
      const { data } = await adminService.stock();
      console.log("[getStock] data", data);

      let suppliers_stock = data.filter((item) => item.kind === "initial");
      setStock(suppliers_stock);
    } catch (error) {
      console.error(error);

      toast({
        position: "top",
        title: "Ha ocurrido un error al obtener el stock",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const updateStock: (item: string, stock: number) => Promise<void> = async (
    item,
    stock
  ) => {
    setLoading(true);

    try {
      const { data } = await adminService.stock_update({
        item,
        stock,
        type: "suppliers",
        kind: "initial",
      });
      console.log("[updateStock] data", data);

      setUpdateStockData({
        item: "",
        stock: 0,
      });

      toast({
        position: "top",
        title: "Se ha actualizado el stock",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      getStock();
    } catch (error) {
      console.error(error);

      toast({
        position: "top",
        title: "Ha ocurrido un error al actualizar el stock",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Suppliers - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box minH="100vh">
        {loading || !stock ? (
          <Spinner />
        ) : (
          <>
            <SlideFade
              in={true}
              style={{ width: "100%" }}
              offsetY={"20px"}
              transition={{ enter: { duration: 0.5 } }}
            >
              <DataTable
                columns={columns}
                data={stock}
                defaultColumnVisibility={{
                  _id: false,
                }}
                actions={[
                  {
                    label: "Actualizar stock",
                    onClick: (row) => {
                      console.log(row);
                      onStockUpdateModalOpen();
                    },
                    buttonProps: {
                      size: "sm",
                      borderRadius: "lg",
                      colorScheme: "brand",
                    },
                  },
                ]}
              />
            </SlideFade>
            <Modal
              onClose={onStockUpdateModalClose}
              isOpen={isStockUpdateModalOpen}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Actualizar stock</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <VStack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    spacing={8}
                  >
                    <FormControl>
                      <FormLabel fontSize={"sm"} color="gray.500" mb={1}>
                        Item
                      </FormLabel>

                      <ChakraSelect
                        variant="filled"
                        colorScheme="brand"
                        placeholder={`Item`}
                        isSearchable
                        noOptionsMessage={() => "No se ha encontrado el item"}
                        options={stock.map((s) => {
                          return {
                            label: `${s.item}`,
                            value: s.item,
                          };
                        })}
                        onChange={(e) => {
                          e &&
                            setUpdateStockData({
                              ...updateStockData,
                              item: e.value,
                            });
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={"sm"} color="gray.500" mb={1}>
                        Unidades de stock entrantes
                      </FormLabel>
                      <Input
                        type="number"
                        value={updateStockData.stock}
                        onChange={(e) =>
                          setUpdateStockData({
                            ...updateStockData,
                            stock: Number(e.target.value),
                          })
                        }
                      />
                      <FormHelperText fontSize={"sm"} color="gray.500" mt={1}>
                        Debe coincidir la unidad de medida almacenada
                      </FormHelperText>
                    </FormControl>
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={onStockUpdateModalClose}>Close</Button>
                  <Button
                    colorScheme="brand"
                    ml={3}
                    onClick={() => {
                      console.log("update stock");
                      updateStock(updateStockData.item, updateStockData.stock);
                      onStockUpdateModalClose();
                    }}
                  >
                    Enviar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    </>
  );
};

export default Suppliers;
