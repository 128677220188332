import {
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
  Spacer,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { Helmet } from "react-helmet";

const STATS: {
  title: string;
  value: number;
  help?: {
    value: number;
    type?: "increase" | "decrease";
  };
}[] = [
  {
    title: "Envíos para hoy",
    value: 20,
  },
  {
    title: "Pedidos pendientes",
    value: 45,
    help: {
      value: 9.05,
      type: "increase",
    },
  },
];

const Home = () => {
  const { colorMode } = useColorMode();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inicio - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box minH="100vh">
        <Alert status="warning">
          <AlertIcon />
          <Text>
            Esta página está en construcción. Por favor, vuelve más tarde.
          </Text>
        </Alert>

        <Spacer h={8} />

        <StatGroup as={SimpleGrid} minChildWidth="20%" spacing={4} w={"full"}>
          {STATS.map((stat) => (
            <Stat
              borderRadius={"2xl"}
              bgColor={colorMode === "light" ? "gray.100" : "gray.900"}
              py={6}
              p={6}
              w={"full"}
              key={stat.title}
            >
              <StatLabel>{stat.title}</StatLabel>
              <StatNumber>{stat.value}</StatNumber>
              {stat.help && (
                <StatHelpText>
                  {stat.help.type && <StatArrow type={stat.help.type} />}
                  {stat.help.value}
                </StatHelpText>
              )}
            </Stat>
          ))}
        </StatGroup>
      </Box>
    </>
  );
};
export default Home;
