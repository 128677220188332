import { useEffect, useState } from "react";

import { useToast } from "@chakra-ui/react";
import { AdminService } from "../services/admin.service";
import { IMenuIngredient } from "../types/types";

const adminService = new AdminService();

const useMenus = () => {
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [menus, setMenus] = useState<IMenuIngredient[]>();

  useEffect(() => {
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = () => {
    setLoading(true);

    const fetch_promises = Promise.all([getMenus()]);
    fetch_promises.finally(() => setLoading(false));
  };

  const getMenus: () => Promise<void> = async () => {
    try {
      const { data } = await adminService.menus();

      console.log("[getMenus] data", data);
      setMenus(data);
    } catch (error) {
      console.error(error);

      toast({
        position: "top",
        title: "Ha ocurrido un error al obtener el stock",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const updateMenus = (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => {
    adminService
      .stock_update({ item, stock: value, type, kind })
      .then(() => {
        getMenus();

        toast({
          position: "top",
          title: "Se han modificado los datos correctamente",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);

        toast({
          position: "top",
          title: "Ha ocurrido un error al modificar los datos",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return { loading, menus, getMenus, updateMenus };
};

export default useMenus;
