import { useEffect, useState } from "react";

import { AdminService } from "../services/admin.service";
import { IStockItem } from "../types/types";

interface UseKitchenCookingProps {
  stock: IStockItem[];
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

interface UseKitchenCookingReturn {
  menusToCook: { [menu: string]: IPriority } | undefined;
  menusCooked: { [menu: string]: number } | undefined;
  stockUpdateHandler: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

interface IPriority {
  high: number;
  medium: number;
  low: number;
  rest: number;
  total: number;
}

const adminService = new AdminService();

const useKitchenCooking: ({
  stock,
  updateStock,
}: UseKitchenCookingProps) => UseKitchenCookingReturn = ({
  stock,
  updateStock,
}) => {
  const [menusToCook, setMenusToCook] = useState<{
    [menu: string]: IPriority;
  }>();
  const [menusCooked, setMenusCooked] = useState<{
    [menu: string]: number;
  }>();

  useEffect(() => {
    getCookingQuantities();
  }, [stock]);

  useEffect(() => {
    if (stock) {
      let cooked_stock = stock.filter((s) => s.kind === "cooked");
      let menus_cooked = {};

      cooked_stock.forEach((s) => {
        let menu_cooked = s.item.split("_")[1];
        (menus_cooked as { [menu: string]: number })[menu_cooked] =
          parseFloat(s.stock as unknown as string) || 0;
      });

      setMenusCooked(menus_cooked);
    }
  }, [stock]);

  const getCookingQuantities = () => {
    adminService
      .cooking_quantities()
      .then(({ data: { cooking_quantities } }) => {
        console.log(
          "[getCookingQuantities] cooking_quantities",
          cooking_quantities
        );
        setMenusToCook(cooking_quantities);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const stockUpdateHandler = (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => {
    updateStock(item, value, type, kind);
  };

  return {
    menusToCook,
    menusCooked,
    stockUpdateHandler,
  };
};

export default useKitchenCooking;
