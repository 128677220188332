import { SlideFade, StackDivider, VStack } from "@chakra-ui/react";

import { images } from "../assets/images/images";
import MenuPackingViz from "../components/MenuPackingViz";
import useKitchenPacking from "../hooks/useKitchenPacking";
import { IStockItem } from "../types/types";

interface KitchenPackingProps {
  stock: IStockItem[];
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const KitchenPacking: React.FC<KitchenPackingProps> = ({
  stock,
  updateStock,
}) => {
  const { menusToPack, menusPacked, stockUpdateHandler } = useKitchenPacking({
    stock,
    updateStock,
  });

  console.log("menusToPack", menusToPack);
  console.log("menusPacked", menusPacked);

  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={16}
    >
      <VStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={6}
        width={"full"}
      >
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={8}
          w={"full"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {menusToPack &&
            menusPacked &&
            Object.keys(menusToPack).map((menu, menu_index) => (
              <SlideFade
                key={menu}
                in={true}
                style={{ width: "100%" }}
                offsetY={"20px"}
                transition={{ enter: { duration: (menu_index + 1) * 0.5 } }}
              >
                <MenuPackingViz
                  key={menu}
                  stock_key_prefix={`packed_${menu}_menu_bags`}
                  heading={{
                    text: `Menú de ${menu}`,
                    icon: images[menu] as string,
                  }}
                  toPack={menusToPack[menu]}
                  packed={menusPacked[menu] || 0}
                  updateStock={stockUpdateHandler}
                />
              </SlideFade>
            ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default KitchenPacking;
