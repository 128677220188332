import auth_bg_pattern from "./files/auth_bg_pattern.svg";
import beef from "./files/beef.svg";
import chicken from "./files/chicken.svg";
import dog_outside from "./files/dog_outside.svg";
import dog_sitting from "./files/dog_sitting.svg";
import logo_candi_black from "./files/logo_candi_black.svg";
import logo_candi_white from "./files/logo_candi_white.svg";
import logo_candi_yellow from "./files/logo_candi_yellow.svg";
import orange_dog from "./files/orange_dog.svg";
import pig from "./files/pig.svg";

interface IImages {
  [key: string]: string | object;
  logo_candi_black: string;
  logo_candi_white: string;
  logo_candi_yellow: string;
  chicken: string;
  beef: string;
  pig: string;
  dog_sitting: string;
  dog_outside: string;
  orange_dog: string;
  auth_bg_pattern: string;
}

export const images: IImages = {
  logo_candi_black: logo_candi_black,
  logo_candi_white: logo_candi_white,
  logo_candi_yellow: logo_candi_yellow,
  chicken: chicken,
  beef: beef,
  pig: pig,
  dog_sitting: dog_sitting,
  dog_outside: dog_outside,
  orange_dog: orange_dog,
  auth_bg_pattern,
};
