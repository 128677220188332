import { ENV } from "./environment.base";

/**
 * Get breed name from code
 *
 * @param code
 *
 * @returns breed name if exists
 */
export const getBreedName = (code: number) => {
  const names = ENV.DOG_BREEDS.filter((b) => b.code === code);

  if (names.length === 0) {
    return undefined;
  }

  return names[0].name;
};

/**
 * Validate email format
 *
 * @param email
 *
 * @returns true if email is valid and false otherwise
 */
export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/**
 * Get formatted number
 *
 * @param number
 *
 * @returns formatted number
 */
export const formatEuro = (number: number) => {
  return number.toLocaleString("es-ES", {
    useGrouping: true,
    minimumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "narrowSymbol",
    style: "currency",
  });
};
