import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { ColorModeScript } from "@chakra-ui/react";
import moment from "moment";
import App from "./App";

moment.locale("es");

const container = document.getElementById("root");

if (!container) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>
);
