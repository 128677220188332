import { Box, Spinner as ChakraSpinner, useColorMode } from "@chakra-ui/react";

interface ISpinnerProps {
  fullscreen?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({ fullscreen = false }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      bgColor={colorMode === "light" ? "gray.100" : "gray.800"}
      blur={"md"}
      p={16}
      w={fullscreen ? "100vw" : "100%"}
      h={fullscreen ? "100vh" : "100%"}
      pos={fullscreen ? "absolute" : "relative"}
      zIndex={9999}
    >
      <ChakraSpinner
        thickness="3px"
        speed="0.65s"
        emptyColor="brand.100"
        color="brand.500"
        size="lg"
      />
    </Box>
  );
};

export default Spinner;
