import { SlideFade, StackDivider, VStack } from "@chakra-ui/react";

import { images } from "../assets/images/images";
import MenuCookingViz from "../components/MenuCookingViz";
import useKitchenCooking from "../hooks/useKitchenCooking";
import { IStockItem } from "../types/types";

interface KitchenCookingProps {
  stock: IStockItem[];
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const KitchenCooking: React.FC<KitchenCookingProps> = ({
  stock,
  updateStock,
}) => {
  const { menusToCook, menusCooked, stockUpdateHandler } = useKitchenCooking({
    stock,
    updateStock,
  });

  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={16}
    >
      <VStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={6}
        width={"full"}
      >
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={8}
          w={"full"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {menusToCook &&
            menusCooked &&
            Object.keys(menusToCook).map((menu, menu_index) => (
              <SlideFade
                key={menu}
                in={true}
                style={{ width: "100%" }}
                offsetY={"20px"}
                transition={{ enter: { duration: (menu_index + 1) * 0.5 } }}
              >
                <MenuCookingViz
                  stock_key={`cooked_${menu}_menu_grams`}
                  heading={{
                    text: `Menú de ${menu}`,
                    icon: images[menu] as string,
                  }}
                  toCook={menusToCook[menu]}
                  cooked={menusCooked[menu] || 0}
                  updateStock={stockUpdateHandler}
                />
              </SlideFade>
            ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default KitchenCooking;
