import { useEffect, useState } from "react";

import { AdminService } from "../services/admin.service";
import { IStockItem } from "../types/types";
import { IPriority } from "./useKitchen";

interface UseKitchenPackingProps {
  stock: IStockItem[];
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

interface UseKitchenPackingReturn {
  menusToPack: { [key: string]: { [grams: string]: IPriority } } | undefined;
  menusPacked: { [key: string]: { [grams: string]: number } } | undefined;
  stockUpdateHandler: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const adminService = new AdminService();

const useKitchenPacking: ({
  stock,
  updateStock,
}: UseKitchenPackingProps) => UseKitchenPackingReturn = ({
  stock,
  updateStock,
}) => {
  const [menusToPack, setMenusToPack] = useState<{
    [menu: string]: { [grams: string]: IPriority };
  }>();
  const [menusPacked, setMenusPacked] = useState<{
    [menu: string]: { [grams: string]: number };
  }>();

  useEffect(() => {
    getPackingQuantities();
  }, []);

  useEffect(() => {
    if (stock) {
      let packed_stock = stock.filter((s) => s.kind === "packed");
      let menus_packed: { [menu: string]: { [grams: string]: number } } = {};

      packed_stock.forEach((s) => {
        let menu_packed = s.item.split("_")[1];
        let grams = parseFloat(s.item.split("_")[4]);

        menus_packed[menu_packed] = {
          ...menus_packed[menu_packed],
          [`${grams}`]: parseFloat(s.stock as unknown as string) || 0,
        };
      });

      console.log("[useKitchenPacking] menus_packed", menus_packed);
      setMenusPacked(menus_packed);
    }
  }, [stock]);

  const getPackingQuantities = () => {
    adminService
      .packing_quantities()
      .then(({ data: { packing_quantities } }) => {
        console.log(
          "[getPackingQuantities] packing_quantities",
          packing_quantities
        );
        setMenusToPack(packing_quantities);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const stockUpdateHandler = (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => {
    updateStock(item, value, type, kind);
  };

  return {
    menusToPack,
    menusPacked,
    stockUpdateHandler,
  };
};

export default useKitchenPacking;
