export const styles = {};

export const colors = {
  brand: {
    50: "#a9a091",
    100: "#978c7b",
    200: "#867965",
    300: "#75664f",
    400: "#635339",
    500: "#524023",
    600: "#4a3a20",
    700: "#42331c",
    800: "#392d19",
    900: "#312615",
  },
  secondary: {
    50: "#a9a091",
    100: "#978c7b",
    200: "#867965",
    300: "#75664f",
    400: "#635339",
    500: "#524023",
    600: "#4a3a20",
    700: "#42331c",
    800: "#392d19",
    900: "#312615",
  },
};

export const fonts = {
  body: `'Inter', sans-serif`,
  heading: `'Braveold', 'Boska', serif`,
};

export const global_styles = `
  @font-face {
    font-family: 'Boska';
    src: url('./fonts/boska/Boska-Variable.ttf') format("truetype-variations");
    font-weight: 1 999;
  }

  @font-face {
    font-family: 'Braveold';
    src: url('./fonts/braveold/Braveold-Black.woff2') format('woff2');
    font-weight: 900;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Braveold';
    src: url('./fonts/braveold/Braveold-Bold.woff2') format('woff2');
    font-weight: 700;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Braveold';
    src: url('./fonts/braveold/Braveold-Medium.woff2') format('woff2');
    font-weight: 500;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Braveold';
    src: url('./fonts/braveold/Braveold-Regular.woff2') format('woff2');
    font-weight: 400;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Braveold';
    src: url('./fonts/braveold/Braveold-Light.woff2') format('woff2');
    font-weight: 200;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`;
