import {
  Alert,
  Box,
  HStack,
  Heading,
  Image,
  Link,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IPet, IPetOrder } from "../types/types";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { NavLink as RouterNavLink } from "react-router-dom";
import { images } from "../assets/images/images";
import Spinner from "../components/Spinner";
import { PetOrderService } from "../services/pet_order.service";

const petOrderService = new PetOrderService();

const OrderDetail = () => {
  let { id } = useParams();

  const [petOrder, setPetOrder] = useState<IPetOrder>();
  const [petOrderLoading, setPetOrderLoading] = useState<boolean>(false);

  useEffect(() => {
    getPetOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPetOrders = async () => {
    if (!id) return;

    setPetOrderLoading(true);

    petOrderService
      .retrieve(id, { expand: ["pet"] })
      .then(({ data }) => {
        console.log(data);
        setPetOrder(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setPetOrderLoading(false));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalle del pedido - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box minH="100vh">
        {petOrderLoading ? (
          <Spinner />
        ) : petOrder ? (
          <VStack
            w={"full"}
            spacing={8}
            alignItems={"start"}
            justifyContent={"start"}
            textAlign={"start"}
          >
            <Heading as={"h1"}>Pedido {(petOrder?.pet as IPet).name}</Heading>
            <VStack
              textAlign={"start"}
              w={"full"}
              alignItems={"start"}
              justifyContent={"start"}
              divider={<StackDivider color={"red.200"} />}
            >
              <VStack
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={2}
              >
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    ID
                  </Text>
                  <Text>{petOrder._id}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Peludo
                  </Text>
                  <Link
                    isExternal
                    as={RouterNavLink}
                    to={`/pet/${(petOrder.pet as IPet)._id}`}
                    color={"blue.700"}
                    fontWeight={"medium"}
                  >
                    {(petOrder.pet as IPet).name} <ExternalLinkIcon mx="2px" />
                  </Link>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Número de bolsas
                  </Text>
                  <Text>{petOrder.bag_count}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Envíos
                  </Text>
                  <Text>{petOrder.deliveries}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Cajas por envío
                  </Text>
                  <Text>{petOrder.package_count}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Ingesta diaria
                  </Text>
                  <Text>{petOrder.total_daily_grams}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Bolsas diarias
                  </Text>
                  <VStack spacing={1} alignItems={"flex-start"}>
                    {Object.keys(petOrder.daily_bags).map((key) => (
                      <HStack key={key}>
                        <Text fontWeight={"semibold"}>{key} gramos:</Text>
                        <Text>
                          {
                            (petOrder.daily_bags as { [key: string]: number })[
                              key
                            ]
                          }{" "}
                          bolsa(s)
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                </HStack>

                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Menú
                  </Text>
                  <VStack spacing={1} alignItems={"flex-start"}>
                    <HStack>
                      <Image src={images.chicken} h={"6"} />
                      <Text fontWeight={"semibold"}>Pollo</Text>
                      <Text>{petOrder.menu.chicken} envases</Text>
                    </HStack>
                    <HStack>
                      <Image src={images.beef} h={"6"} />
                      <Text fontWeight={"semibold"}>Ternera</Text>
                      <Text>{petOrder.menu.beef} envases</Text>
                    </HStack>
                  </VStack>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        ) : (
          <>
            <Alert>No se encontró el pedido {id}</Alert>
          </>
        )}
      </Box>
    </>
  );
};

export default OrderDetail;
