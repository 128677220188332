import {
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import PriorityBar from "../components/PriorityBar";
import { IPriority } from "../hooks/useKitchen";

interface MenuCookingVizProps {
  stock_key: string;
  heading: {
    text: string;
    icon?: string;
  };
  toCook: IPriority;
  cooked: number;
  updateStock: (
    item: string,
    value: number,
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc",
    kind: string
  ) => void;
}

const MenuCookingViz: React.FC<MenuCookingVizProps> = ({
  stock_key,
  heading,
  toCook,
  cooked,
  updateStock,
}) => {
  const {
    isOpen: isGramsUpdateOpen,
    onToggle: onGramsUpdateToggle,
    onClose: onGramsUpdateClose,
  } = useDisclosure();

  const [gramsUpdateInput, setGramsUpdateInput] = useState<number>();

  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={6}
    >
      <VStack
        w={"full"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={4}
      >
        <VStack
          w={"full"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={4}
        >
          <HStack alignItems={"center"} spacing={2}>
            {heading.icon && <Image src={heading.icon} h={8} />}
            <Heading
              as={"h2"}
              size={"lg"}
              fontWeight={"semibold"}
              lineHeight={1}
              mb={0}
            >
              {heading.text}
            </Heading>
          </HStack>
          <HStack
            w={"full"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <HStack
              w={"full"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Text
                fontSize={"3xl"}
                fontWeight={"extrabold"}
                fontFamily={"mono"}
                letterSpacing={"tight"}
              >
                {(cooked / 1000).toLocaleString("es-ES", {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
                <Text as="span" fontSize={"xl"} fontWeight={"medium"}>
                  {" "}
                  /{" "}
                  {toCook.total.toLocaleString("es-ES", {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })}{" "}
                  Kg
                </Text>
              </Text>
              {/* DESGLOSE POR INGREDIENTE */}
            </HStack>
            <Popover
              returnFocusOnClose={false}
              isOpen={isGramsUpdateOpen}
              onClose={onGramsUpdateClose}
              placement="right"
            >
              <PopoverTrigger>
                <IconButton
                  aria-label="actualizar cocinado"
                  icon={<AddIcon />}
                  colorScheme="brand"
                  borderRadius={"lg"}
                  onClick={onGramsUpdateToggle}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontSize={"md"} fontWeight={"bold"} pr={10}>
                  ¿Cuántos gramos has cocinado del menú?
                </PopoverHeader>
                <PopoverBody>
                  <FormControl>
                    <Input
                      type="number"
                      value={gramsUpdateInput}
                      onChange={(e) => {
                        setGramsUpdateInput(parseFloat(e.currentTarget.value));
                      }}
                    />
                    <FormHelperText>
                      Introduce el valor en gramos
                    </FormHelperText>
                    <Button
                      type="submit"
                      size={"sm"}
                      w={"full"}
                      colorScheme="brand"
                      borderRadius={"lg"}
                      mt={4}
                      disabled={!gramsUpdateInput}
                      onClick={() => {
                        updateStock(
                          stock_key,
                          gramsUpdateInput!,
                          "cooking",
                          "cooked"
                        );

                        setGramsUpdateInput(0);
                        onGramsUpdateClose();
                      }}
                    >
                      Enviar
                    </Button>
                  </FormControl>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </VStack>
        <VStack
          w={"full"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={0}
        >
          {/* ########################## */}
          {/* TOTAL GRAMS */}
          {/* ########################## */}
          {toCook.total - cooked > 0 && (
            <Text fontWeight={"semibold"} fontSize={"16px"}>
              Te quedan{" "}
              {(toCook.total - cooked).toLocaleString("es-ES", {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}{" "}
              Kg por cocinar
            </Text>
          )}

          {/* ########################## */}
          {/* TODAY GRAMS */}
          {/* ########################## */}
          {toCook.high && toCook.high - cooked > 0 && (
            <Text fontWeight={"bold"} color={"red.500"}>
              Tienes pendientes{" "}
              {Math.min(toCook.high, toCook.high - cooked).toLocaleString(
                "es-ES",
                {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                }
              )}{" "}
              Kg para hoy
            </Text>
          )}

          {/* ########################## */}
          {/* TOMORROW GRAMS */}
          {/* ########################## */}
          {toCook.medium && toCook.high + toCook.medium - cooked > 0 && (
            <Text fontWeight={"bold"} color={"yellow.500"}>
              Tienes pendientes{" "}
              {Math.min(
                toCook.medium,
                toCook.high + toCook.medium - cooked
              ).toLocaleString("es-ES", {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}{" "}
              Kg para mañana
            </Text>
          )}

          {/* ########################## */}
          {/* 2 DAYS FROM TODAY GRAMS */}
          {/* ########################## */}
          {toCook.low &&
            toCook.high + toCook.medium + toCook.low - cooked > 0 && (
              <Text fontWeight={"bold"} color={"green.500"}>
                Tienes pendientes{" "}
                {Math.min(
                  toCook.low,
                  toCook.high + toCook.medium + toCook.low - cooked
                ).toLocaleString("es-ES", {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}{" "}
                Kg para dentro de 2 días
              </Text>
            )}
        </VStack>
      </VStack>
      <VStack
        w={"full"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        {/* ########################## */}
        {/* PRIORITY BAR */}
        {/* ########################## */}
        <PriorityBar
          // unit="Kg"
          data={{
            high: { value: toCook.high },
            medium: { value: toCook.medium },
            low: { value: toCook.low },
            rest: { value: toCook.rest },
          }}
          valuesFormatter={(value) =>
            value.toLocaleString("es-ES", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })
          }
        />

        {/* ########################## */}
        {/* PROGRESS BAR */}
        {/* ########################## */}
        <Progress
          w={"full"}
          colorScheme="green"
          size="md"
          value={(cooked / 1000 / toCook.total) * 100}
          borderRadius={8}
        />
        <Text
          fontFamily={"mono"}
          fontSize={"sm"}
          fontWeight={"medium"}
          lineHeight={"shorter"}
        >
          {((cooked / 1000 / toCook.total) * 100).toLocaleString("es-ES", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          % cocinado del total
        </Text>
      </VStack>
    </VStack>
  );
};

export default MenuCookingViz;
