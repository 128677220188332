import { SlideFade, Text, VStack } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../components/DataTable";
import Spinner from "../components/Spinner";
import { formatEuro } from "../constants/utils";
import useMenus from "../hooks/useMenus";
import { IMenuIngredient } from "../types/types";

const columnHelper = createColumnHelper<IMenuIngredient>();

const columns = [
  columnHelper.accessor("ingredient", {
    cell: (info) => info.getValue(),
    header: "Ingrediente",
    footer: ({ table }) => "TOTAL",
  }),
  columnHelper.accessor("price", {
    cell: (info) => (
      <Text
        color={info.getValue() ? "unset" : "yellow.500"}
        fontWeight={info.getValue() ? "unset" : "bold"}
      >
        {formatEuro(info.getValue())}
      </Text>
    ),
    header: "Precio/Kg",
  }),
  columnHelper.accessor("chicken", {
    cell: (info) => info.getValue().toFixed(2),
    header: "Pollo (gr.)",
    footer: ({ table }) =>
      formatEuro(
        table
          .getFilteredRowModel()
          .rows.reduce(
            (total, row) =>
              total +
              ((row.getValue("price") as number) / 1000) *
                (row.getValue("chicken") as number),
            0
          )
      ),
  }),
  columnHelper.accessor("beef", {
    cell: (info) => info.getValue().toFixed(2),
    header: "Ternera (gr.)",
    footer: ({ table }) =>
      formatEuro(
        table
          .getFilteredRowModel()
          .rows.reduce(
            (total, row) =>
              total +
              ((row.getValue("price") as number) / 1000) *
                (row.getValue("beef") as number),
            0
          )
      ),
  }),
  columnHelper.accessor("pig", {
    cell: (info) => info.getValue().toFixed(2),
    header: "Cerdo (gr.)",
    footer: ({ table }) => {
      let total_grams = table
        .getFilteredRowModel()
        .rows.reduce(
          (total, row) => total + (row.getValue("pig") as number),
          0
        );
      let total_price = table
        .getFilteredRowModel()
        .rows.reduce(
          (total, row) =>
            total +
            ((row.getValue("price") as number) / 1000) *
              (row.getValue("pig") as number),
          0
        );

      return formatEuro((total_price / total_grams) * 1000);
    },
  }),
  columnHelper.accessor("fish", {
    cell: (info) => info.getValue().toFixed(2),
    header: "Pescado (gr.)",
    footer: ({ table }) =>
      formatEuro(
        table
          .getFilteredRowModel()
          .rows.reduce(
            (total, row) =>
              total +
              ((row.getValue("price") as number) / 1000) *
                (row.getValue("fish") as number),
            0
          )
      ),
  }),
  columnHelper.accessor("supplier", {
    cell: (info) => info.getValue(),
    header: "Proveedor",
  }),
];

const Menus = () => {
  const { loading, menus } = useMenus();

  return (
    <VStack
      w={"full"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      spacing={4}
    >
      {!loading && menus && menus.length > 0 ? (
        <SlideFade
          in={true}
          style={{ width: "100%" }}
          offsetY={"20px"}
          transition={{ enter: { duration: 0.5 } }}
        >
          <DataTable columns={columns} data={menus} />
        </SlideFade>
      ) : (
        <Spinner />
      )}
    </VStack>
  );
};

export default Menus;
