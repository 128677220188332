import { Box, ChakraProvider, useToast } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { IJwtContext, ILoadingContext, IUserContext } from "./types/types";

import { Global } from "@emotion/react";
import Auth0 from "auth0";
import axios from "axios";
import Navbar from "./components/Navbar";
import Spinner from "./components/Spinner";
import Auth from "./pages/Auth";
import Deliveries from "./pages/Deliveries";
import DeliveryDetail from "./pages/DeliveryDetail";
import Home from "./pages/Home";
import Kitchen from "./pages/Kitchen";
import OrderDetail from "./pages/OrderDetail";
import Orders from "./pages/Orders";
import PetDetail from "./pages/PetDetail";
import Pets from "./pages/Pets";
import ResetPassword from "./pages/ResetPassword";
import Suppliers from "./pages/Suppliers";
import Utils from "./pages/Utils";
import { UserService } from "./services/user.service";
import theme from "./theme/index";
import { global_styles } from "./theme/styles";

export const UserContext = createContext<IUserContext>({} as IUserContext);
export const JwtContext = createContext<IJwtContext>({} as IJwtContext);
export const LoadingContext = createContext<ILoadingContext>(
  {} as ILoadingContext
);

const userService = new UserService();

const App = () => {
  const toast = useToast();

  const [user, setUser] = useState<Auth0.User | false | undefined>();
  const [jwt, setJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const saveJwt = () => {
    const local_jwt = localStorage.getItem("jwt");
    if (local_jwt) {
      setJwt(localStorage.getItem("jwt"));
    } else {
      setUser(false);
    }
  };

  useEffect(() => {
    saveJwt();
    window.addEventListener("storage", saveJwt);

    return () => {
      window.removeEventListener("storage", saveJwt);
    };
  }, []);

  useEffect(() => {
    getUser();

    // Add a request interceptor
    axios.interceptors.request.use((config) => {
      let local_jwt = localStorage.getItem("jwt");
      if (local_jwt) config.headers!["Authorization"] = "Bearer " + local_jwt;

      return config;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const getUser = () => {
    if (jwt) {
      userService
        .get()
        .then(({ data }) => {
          console.log("[getUser] data", data);

          if ((data.auth0 as Auth0.User).user_metadata?.isAdmin) {
            console.log("[getUser] isAdmin");
            setUser(data.auth0);
          } else {
            setUser(false);

            toast({
              title: "No autorizado",
              description:
                "No tienes acceso a esta página. Se notificará este intento de inicio de sesión al administrador.",
              duration: null,
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log("[getUser] err", err);
          setUser(false);

          let retry = false;
          if (retry) {
            // TO_DO: retry get user based on specific error
            getUser();
          }
        });
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Global styles={global_styles} />
      <UserContext.Provider
        value={{
          user,
          setUser,
          getUser,
        }}
      >
        <LoadingContext.Provider value={{ loading, setLoading }}>
          <BrowserRouter>
            {user === false ? (
              <Routes>
                <Route path="/auth" element={<Auth />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/auth" />} />
              </Routes>
            ) : user === undefined ? (
              <Spinner fullscreen />
            ) : (
              <>
                <Navbar />
                <Box p={4}>
                  <Routes>
                    <Route path="/suppliers" element={<Suppliers />} />
                    <Route path="/kitchen/*" element={<Kitchen />} />
                    <Route path="/pets" element={<Pets />} />
                    <Route path="/pets/:id" element={<PetDetail />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/:id" element={<OrderDetail />} />
                    <Route path="/deliveries" element={<Deliveries />} />
                    <Route
                      path="/deliveries/:id"
                      element={<DeliveryDetail />}
                    />
                    <Route path="/utils/*" element={<Utils />} />
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Box>
              </>
            )}
          </BrowserRouter>
        </LoadingContext.Provider>
      </UserContext.Provider>
      {loading && <Spinner fullscreen />}
    </ChakraProvider>
  );
};

export default App;
