import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HiChevronRight, HiXMark } from "react-icons/hi2";

import React from "react";
import { Link } from "react-router-dom";
import { images } from "../assets/images/images";
import { useAuth } from "../hooks/useAuth";

const Auth: React.FC = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    loading,
    errorMsg,
    handleAuthFormSubmit,
  } = useAuth();

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      alignItems={"center"}
      justifyContent={"center"}
      m={"0 auto"}
      backgroundImage={`url(${images.auth_bg_pattern})`}
    >
      <SlideFade
        in={true}
        style={{ width: "100%" }}
        offsetY={"50px"}
        transition={{ enter: { duration: 0.5 } }}
      >
        <VStack
          spacing={10}
          w={"full"}
          maxW={"lg"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          m={"0 auto"}
          bgColor={"white"}
          p={8}
          borderRadius={"2xl"}
          borderWidth={2.5}
          borderColor={"brand.500"}
        >
          <Heading as="h1" fontWeight={"medium"} size="lg">
            Gestionar la{" "}
            <Text as="span" color={"brand.500"} fontWeight={"semibold"}>
              #candifam
            </Text>
          </Heading>

          <VStack
            spacing={8}
            as={"form"}
            w={"full"}
            alignItems={"flex-start"}
            onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
              e.preventDefault();
              handleAuthFormSubmit();
            }}
          >
            <VStack w={"full"} alignItems={"flex-start"} spacing={3}>
              <FormControl id="email" isRequired>
                <FormLabel fontWeight={"medium"}>Correo electrónico</FormLabel>
                <Input
                  type="email"
                  size={"lg"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel fontWeight={"medium"}>Contraseña</FormLabel>
                <Input
                  type="password"
                  size={"lg"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormControl>

              {/* <Collapse in={otpCodeSent} animateOpacity>
                <FormControl id="otp" isRequired>
                  <FormLabel>Código</FormLabel>
                  <HStack>
                    <PinInput
                      size={"lg"}
                      otp
                      value={otp}
                      onChange={(v) => setOtp(v)}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </FormControl>
              </Collapse> */}
            </VStack>

            <Collapse in={!!errorMsg}>
              <HStack alignItems={"flex-start"}>
                <Icon
                  as={HiXMark}
                  color={"red.500"}
                  strokeWidth={1}
                  h={5}
                  w={5}
                  mt={"0.5"}
                />
                <Text color={"red.500"}>{errorMsg}</Text>
              </HStack>
            </Collapse>

            <Button
              w={"full"}
              borderRadius={"2xl"}
              justifyContent={"space-between"}
              size={"lg"}
              colorScheme="brand"
              type="submit"
              rightIcon={<Icon as={HiChevronRight} />}
              isLoading={loading}
            >
              Iniciar sesión
            </Button>

            <Text fontWeight={"medium"} size="sm">
              ¿Has olvidado la contraseña?{" "}
              <Link to={"/reset-password"}>
                <Box as={"span"} fontWeight={"medium"} color={"brand.600"}>
                  ¡Recupérala!
                </Box>
              </Link>
            </Text>
          </VStack>
        </VStack>
      </SlideFade>
    </Flex>
  );
};

export default Auth;
