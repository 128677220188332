import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Tag,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IDelivery, IPet, IUser } from "../types/types";

import { createColumnHelper } from "@tanstack/react-table";
import { Select as ChakraSelect } from "chakra-react-select";
import moment from "moment";
import { Helmet } from "react-helmet";
import Stripe from "stripe";
import { images } from "../assets/images/images";
import { DataTable } from "../components/DataTable";
import Spinner from "../components/Spinner";
import { ENV } from "../constants/environment.base";
import { DeliveryService } from "../services/delivery.service";
import { UserService } from "../services/user.service";

const columnHelper = createColumnHelper<IDelivery>();

const columns = [
  columnHelper.accessor("_id", {
    cell: (info) => info.getValue(),
    header: "ID",
  }),
  columnHelper.accessor("pet", {
    cell: (info) => (info.getValue() as IPet).name,
    header: "Peludo",
  }),
  columnHelper.accessor("delivery_date", {
    cell: (info) => (
      <VStack spacing={0}>
        <Text fontSize={"sm"} fontWeight={"medium"}>
          {moment(info.getValue()).endOf("day").fromNow()}
        </Text>
        <Text fontSize={"sm"}>
          {moment(info.getValue()).format("DD/MM/YYYY")}
        </Text>
      </VStack>
    ),
    header: "Fecha de entrega",
    sortDescFirst: true,
  }),
  columnHelper.accessor("is_trial", {
    cell: (info) => (info.getValue() ? "Sí" : "No"),
    header: "Es transición",
  }),
  columnHelper.accessor("status", {
    cell: (info) => (
      <>
        <Tag
          size={"sm"}
          variant="solid"
          colorScheme={ENV.DELIVERY_KEY_CAPTIONS[info.getValue()].color}
        >
          {ENV.DELIVERY_KEY_CAPTIONS[info.getValue()].caption}
        </Tag>
      </>
    ),
    header: "Estado",
  }),
  columnHelper.accessor("content.menu", {
    cell: (info) => (
      <VStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={1}
      >
        {Object.keys(info.getValue()).map((key) => (
          <HStack key={key}>
            <Image src={images[key] as string} h={5} />
            <Text>
              {(info.getValue() as unknown as { [key: string]: number })[key]}
            </Text>
          </HStack>
        ))}
      </VStack>
    ),
    header: "Menú",
    enableSorting: false,
  }),
  columnHelper.accessor("content.bag_count", {
    cell: (info) => info.getValue(),
    header: "Bolsitas totales",
    meta: {
      textAlign: "center",
    },
  }),
  columnHelper.accessor("content.total_daily_grams", {
    cell: (info) => <>{info.getValue()} gr.</>,
    header: "Ingesta diaria",
  }),
  columnHelper.accessor("content.daily_bags", {
    cell: (info) => (
      <VStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={1}
      >
        {Object.keys(info.getValue()).map((key) => (
          <HStack key={key}>
            <Text>
              {key}: {(info.getValue() as { [key: string]: number })[key]}
            </Text>
          </HStack>
        ))}
      </VStack>
    ),
    header: "Bolsitas diarias",
    enableSorting: false,
  }),
  columnHelper.accessor("is_secondary", {
    cell: (info) => (info.getValue() ? "Sí" : "No"),
    header: "Es segundo envío",
  }),
];

const deliveryService = new DeliveryService();
const userService = new UserService();

const Deliveries = () => {
  const {
    isOpen: isNewDeliveryModalOpen,
    onOpen: onNewDeliveryModalOpen,
    onClose: onNewDeliveryModalClose,
  } = useDisclosure();

  const [deliveries, setDeliveries] = useState<IDelivery[]>([]);
  const [deliveriesLoading, setDeliveriesLoading] = useState<boolean>(false);

  const [users, setUsers] = useState<IUser[]>();
  const [usersLoading, setUsersLoading] = useState<boolean>(false);

  useEffect(() => {
    getDeliveries();
  }, []);

  const getDeliveries = async () => {
    setDeliveriesLoading(true);

    deliveryService
      .list({ expand: ["pet"] })
      .then(({ data }) => {
        console.log(data);
        setDeliveries(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setDeliveriesLoading(false));
  };

  const getUsers = async () => {
    setUsersLoading(true);

    userService
      .list()
      .then(({ data }) => {
        console.log(data);
        setUsers(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setUsersLoading(false));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Envíos - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box minH="100vh">
        {deliveriesLoading ? (
          <Spinner />
        ) : (
          <>
            <SlideFade
              in={!deliveriesLoading}
              style={{ width: "100%" }}
              offsetY={"20px"}
              transition={{ enter: { duration: 0.5 } }}
            >
              <DataTable
                columns={columns}
                data={deliveries}
                rowDetailPath={"deliveries"}
                rowDetailIdentifier={"_id"}
                defaultSorting={[{ id: "delivery_date", desc: false }]}
                defaultColumnVisibility={{
                  _id: false,
                }}
                actions={[
                  {
                    label: "Nuevo envío",
                    onClick: (row) => {
                      console.log(row);

                      getUsers();
                      onNewDeliveryModalOpen();
                    },
                    buttonProps: {
                      size: "sm",
                      borderRadius: "lg",
                      colorScheme: "brand",
                    },
                  },
                ]}
              />
            </SlideFade>
          </>
        )}
      </Box>

      <Modal
        onClose={onNewDeliveryModalClose}
        isOpen={isNewDeliveryModalOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo envío</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {usersLoading ? (
              <Spinner />
            ) : users ? (
              <VStack
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={8}
              >
                <FormControl>
                  <FormLabel fontSize={"sm"} color="gray.500" mb={1}>
                    Usuario
                  </FormLabel>
                  <ChakraSelect
                    variant="filled"
                    colorScheme="brand"
                    placeholder={`Usuario`}
                    isSearchable
                    noOptionsMessage={() => "No se ha encontrado el usuario"}
                    options={users.map((user) => {
                      return {
                        label: `${(user.stripe as Stripe.Customer).email} (${
                          (user.stripe as Stripe.Customer).name
                        })`,
                        value: user._id,
                      };
                    })}
                    onChange={(e) => console.log(e)}
                  />

                  <FormHelperText fontSize={"sm"} color="gray.500" mt={1}>
                    We'll never share your email.
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"sm"} color="gray.500" mb={1}>
                    Peludo
                  </FormLabel>
                  <Input type="text" />
                  <FormHelperText fontSize={"sm"} color="gray.500" mt={1}>
                    We'll never share your email.
                  </FormHelperText>
                </FormControl>
              </VStack>
            ) : (
              <Alert status="error" fontSize={"sm"} borderRadius={"2xl"}>
                <AlertIcon alignSelf={"flex-start"} />
                Ha ocurrido un error obteniendo los datos necesarios para crear
                un envío
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onNewDeliveryModalClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Deliveries;
