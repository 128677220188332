import * as React from "react";

import {
  ChevronDownIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  ThemingProps,
  Tr,
  chakra,
  useColorMode,
} from "@chakra-ui/react";
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { useNavigate } from "react-router";

export type DataTableProps<Data extends object> = {
  tableStyleVariant?: ThemingProps<"Table">["variant"];
  data: Data[];
  columns: ColumnDef<Data, any>[];
  rowDetailPath?: string;
  rowDetailIdentifier?: string;
  showColumnVisibilityButton?: boolean;
  defaultColumnVisibility?: VisibilityState;
  defaultSorting?: SortingState;
  actions?: {
    label: string;
    onClick: (row: any) => void;
    buttonProps?: ButtonProps;
  }[];
};

export function DataTable<Data extends object>({
  tableStyleVariant = "striped",
  data,
  columns,
  rowDetailPath,
  rowDetailIdentifier = "id",
  showColumnVisibilityButton = true,
  defaultColumnVisibility,
  defaultSorting,
  actions,
}: DataTableProps<Data>) {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const [sorting, setSorting] = React.useState<SortingState>(
    defaultSorting ? defaultSorting : []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(defaultColumnVisibility || {});

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility,
    },
  });

  return (
    <>
      <HStack justifyContent={"flex-end"} py={4}>
        {showColumnVisibilityButton && (
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size={"sm"}
              borderRadius={"lg"}
            >
              Columnas
            </MenuButton>
            <MenuList>
              {table.getAllLeafColumns().map((column) => (
                <MenuItem key={column.id}>
                  <Checkbox
                    width={"full"}
                    size={"sm"}
                    isChecked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  >
                    {column.columnDef.header as string}
                  </Checkbox>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        {actions &&
          actions.map((action) => (
            <Button
              key={action.label}
              onClick={() => action.onClick(table.getRowModel().rows)}
              {...action.buttonProps}
            >
              {action.label}
            </Button>
          ))}
      </HStack>
      <Table size="md" variant={tableStyleVariant}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;

                return (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? "pointer" : "unset"}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    px={1.5}
                    py={2.5}
                    fontFamily={"sans-serif"}
                    textAlign={meta?.textAlign}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {header.column.getIsSorted() ? (
                      <chakra.span pl="2">
                        {header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              cursor={rowDetailPath && rowDetailIdentifier ? "pointer" : "auto"}
              _hover={{
                backgroundColor:
                  rowDetailPath && rowDetailIdentifier
                    ? colorMode === "light"
                      ? "gray.100"
                      : "gray.700"
                    : "unset",
              }}
              onClick={() => {
                if (rowDetailPath && rowDetailIdentifier) {
                  console.log(row);

                  navigate(
                    `/${rowDetailPath}/${row.getValue(rowDetailIdentifier)}`
                  );
                }
              }}
            >
              {row.getVisibleCells().map((cell) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    p={1.5}
                    isNumeric={meta?.isNumeric}
                    textAlign={meta?.textAlign}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>

        <Tfoot>
          {table.getAllColumns().some((c) => c.columnDef.footer) &&
            table.getFooterGroups().map((footerGroup) => (
              <Tr bgColor={colorMode === "light" ? "brand.100" : "brand.700"}>
                {footerGroup.headers.map((header, headerIndex) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = header.column.columnDef.meta;

                  return (
                    <Th
                      key={`${header.id}-${headerIndex}`}
                      isNumeric={meta?.isNumeric}
                      px={1.5}
                      py={2.5}
                      fontFamily={"sans-serif"}
                      fontSize={"1rem"}
                      textAlign={meta?.textAlign}
                    >
                      {flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
        </Tfoot>
      </Table>
    </>
  );
}
