"use client";

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FiChevronDown, FiHome } from "react-icons/fi";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";

import Auth0 from "auth0";
import { FaTruckPickup } from "react-icons/fa";
import { UserContext } from "../App";
import { images } from "../assets/images/images";

// interface IMenuHeaderItemProps {
//   children: React.ReactNode;
// }

interface INavLinkProps {
  href: string;
  children: React.ReactNode;
  sublink?: boolean;
}

interface INavbarProps {}

const Navbar: React.FC<INavbarProps> = () => {
  const location = useLocation();

  const { user } = useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  const [sublinks, setSublinks] =
    useState<{ caption: string; destination: string }[]>();

  useEffect(() => {
    let root_location = "/" + location.pathname.split("/")[1];
    let index = NAV_LINKS.findIndex((n) => n.destination === root_location);

    setSublinks(index > -1 ? NAV_LINKS[index].sublinks : undefined);
  }, [location.pathname]);

  return (
    <>
      <Box>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          minH={12}
          maxH={12}
          px={4}
          bg={useColorModeValue("gray.100", "gray.900")}
        >
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack alignItems={"flex-end"} spacing={{ base: 2, md: 4 }}>
            <Image
              src={
                colorMode === "light"
                  ? images.logo_candi_black
                  : images.logo_candi_white
              }
              h={"2em"}
            />
            <Text
              display={{ base: "none", md: "block" }}
              fontSize={{ base: "md", md: "lg" }}
              fontWeight="bold"
              lineHeight={1}
            >
              Management
            </Text>
          </HStack>
          <Menu closeOnSelect={false}>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack display={{ base: "none", md: "flex" }} spacing={1}>
                <Text fontSize="sm">
                  {(user as Auth0.User).user_metadata!.nickname}
                </Text>
                <Icon as={FiChevronDown} />
              </HStack>
              <HStack display={{ base: "block", md: "none" }} spacing={1}>
                <Avatar name="Jaime Dordio" bg="brand.500" size={"sm"} />
              </HStack>
            </MenuButton>
            <MenuList
              borderColor={useColorModeValue("gray.200", "gray.800")}
              py={3}
              overflow={"hidden"}
            >
              <MenuItem
                onClick={() => {
                  localStorage.removeItem("jwt");
                  window.dispatchEvent(new Event("storage"));
                }}
              >
                Cerrar sesión
              </MenuItem>
              <MenuItem>
                Modo oscuro
                <Switch
                  size="sm"
                  ml={"auto"}
                  isChecked={colorMode === "dark"}
                  onChange={(e) => toggleColorMode()}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <VStack
          spacing={0}
          display={{ base: "none", md: "flex" }}
          justifyContent={"flex-start"}
          alignItems={"center"}
          h={"fit-content"}
          w={"full"}
        >
          <HStack
            spacing={8}
            px={4}
            py={1}
            alignItems={"center"}
            borderBottomWidth={1}
            w={"full"}
          >
            <HStack
              as={"nav"}
              spacing={2}
              display={{ base: "none", md: "flex" }}
              flex={1}
            >
              {NAV_LINKS.map((link) => (
                <NavLink key={link.destination} href={link.destination}>
                  <Text as={"span"} fontWeight={"semibold"}>
                    {link.caption}
                  </Text>
                </NavLink>
              ))}
            </HStack>
          </HStack>
          {sublinks && (
            <HStack
              spacing={8}
              px={4}
              py={1}
              alignItems={"center"}
              borderBottomWidth={1}
              w={"full"}
            >
              <HStack
                as={"nav"}
                spacing={2}
                display={{ base: "none", md: "flex" }}
                flex={1}
              >
                {sublinks.map((sublink) => (
                  <NavLink
                    key={sublink.destination}
                    href={sublink.destination}
                    sublink
                  >
                    <Text as={"span"}>{sublink.caption}</Text>
                  </NavLink>
                ))}
              </HStack>
            </HStack>
          )}
        </VStack>

        {isOpen ? (
          <Box
            pb={4}
            px={4}
            mt={4}
            borderBottomWidth={1}
            display={{ md: "none" }}
          >
            <Stack as={"nav"} spacing={4}>
              {NAV_LINKS.map((link) => (
                <NavLink key={link.destination} href={link.destination}>
                  {link.caption}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export const NAV_LINKS = [
  {
    caption: "Inicio",
    destination: "/",
    description: "Vista inicial de gestión para Candi",
    accentColor: "brand.200",
    icon: FiHome,
  },
  {
    caption: "Suppliers",
    destination: "/suppliers",
    description: "Gestionar los pedidos a proveedores",
    accentColor: "blue.200",
    icon: FaTruckPickup,
  },
  {
    caption: "Kitchen",
    destination: "/kitchen",
    description: "Vista para la fase de cocinado y envasado",
    accentColor: "brand.200",
    icon: FiHome,
    sublinks: [
      {
        caption: "Cooking",
        destination: "/kitchen/cooking",
      },
      {
        caption: "Packing",
        destination: "/kitchen/packing",
      },
      {
        caption: "Billing",
        destination: "/kitchen/billing",
      },
    ],
  },
  {
    caption: "Peludos",
    destination: "/pets",
    description: "Gestionar los peludos de los clientes",
    accentColor: "blue.200",
    icon: FaTruckPickup,
  },
  {
    caption: "Pedidos",
    destination: "/orders",
    description: "Gestionar los pedidos de los clientes",
    accentColor: "blue.200",
    icon: FaTruckPickup,
  },
  {
    caption: "Envíos",
    destination: "/deliveries",
    description: "Gestionar los envíos de los clientes",
    accentColor: "green.200",
    icon: FaTruckPickup,
  },
  {
    caption: "Utils",
    destination: "/utils",
    description: "Utilidades para el desarrollo",
    accentColor: "blue.200",
    icon: FaTruckPickup,
    sublinks: [
      {
        caption: "Menus",
        destination: "/utils/menus",
      },
    ],
  },
];

// const MenuHeaderItem: React.FC<IMenuHeaderItemProps> = ({ children }) => {
//   return (
//     <Text
//       px={3}
//       mb={2}
//       fontSize="12"
//       fontWeight={"bold"}
//       textTransform={"uppercase"}
//       color={useColorModeValue("gray.500", "gray.400")}
//     >
//       {children}
//     </Text>
//   );
// };

const NavLink: React.FC<INavLinkProps> = ({
  href,
  children,
  sublink = false,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Link
      as={RouterNavLink}
      to={href}
      _activeLink={{
        fontWeight: !sublink ? "semibold" : "medium",
        color: "white",
        backgroundColor: !sublink
          ? colorMode === "light"
            ? "brand.200"
            : "brand.600"
          : colorMode === "light"
          ? "blue.200"
          : "blue.600",
      }}
      px={2}
      py={1}
      rounded={"full"}
      lineHeight={1}
      _hover={{
        textDecoration: "none",
        bg: !sublink
          ? colorMode === "light"
            ? "brand.200"
            : "brand.600"
          : colorMode === "light"
          ? "blue.200"
          : "blue.600",

        // bg: useColorModeValue("gray.200", "gray.600"),
      }}
    >
      {children}
    </Link>
  );
};

export default Navbar;
