import { Box } from "@chakra-ui/react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Helmet } from "react-helmet";
import Spinner from "../components/Spinner";
import useKitchen from "../hooks/useKitchen";
import KitchenBilling from "./KitchenBilling";
import KitchenCooking from "./KitchenCooking";
import KitchenPacking from "./KitchenPacking";

const Kitchen = () => {
  const { loading, stock, updateStock } = useKitchen();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kitchen - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box minH="100vh">
        {loading || !stock ? (
          <Spinner fullscreen />
        ) : (
          <Routes>
            <Route
              path="cooking"
              element={
                <KitchenCooking stock={stock} updateStock={updateStock} />
              }
            />
            <Route
              path="packing"
              element={
                <KitchenPacking stock={stock} updateStock={updateStock} />
              }
            />
            <Route path="billing" element={<KitchenBilling />} />
            <Route path="*" element={<Navigate to="/kitchen/cooking" />} />
          </Routes>
        )}
      </Box>
    </>
  );
};

export default Kitchen;
