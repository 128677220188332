import { Box, HStack, Image, SlideFade, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IPet, IPetOrder } from "../types/types";

import { createColumnHelper } from "@tanstack/react-table";
import { Helmet } from "react-helmet";
import { images } from "../assets/images/images";
import { DataTable } from "../components/DataTable";
import Spinner from "../components/Spinner";
import { PetOrderService } from "../services/pet_order.service";

const columnHelper = createColumnHelper<IPetOrder>();

const columns = [
  columnHelper.accessor("_id", {
    cell: (info) => info.getValue(),
    header: "ID",
  }),
  columnHelper.accessor("pet", {
    cell: (info) => (info.getValue() as IPet).name,
    header: "Peludo",
  }),
  columnHelper.accessor("total_daily_grams", {
    cell: (info) => <>{info.getValue()} gramos</>,
    header: "Ingesta diaria",
  }),
  columnHelper.accessor("menu", {
    cell: (info) => (
      <HStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={4}
      >
        <HStack>
          <Image src={images.chicken} h={5} />
          <Text>{info.getValue().chicken}</Text>
        </HStack>
        <HStack>
          <Image src={images.beef} h={5} />
          <Text>{info.getValue().beef}</Text>
        </HStack>
        {/* <HStack>
          <Image src={images.pig} h={5} />
          <Text>{info.getValue().pig}</Text>
        </HStack> */}
      </HStack>
    ),
    header: "Menú",
    enableSorting: false,
  }),
  columnHelper.accessor("bag_count", {
    cell: (info) => info.getValue(),
    header: "Bolsas mensuales",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("deliveries", {
    cell: (info) => info.getValue(),
    header: "Envíos",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("package_count", {
    cell: (info) => info.getValue(),
    header: "Cajas por envío",
    meta: {
      isNumeric: true,
    },
  }),
];

const petOrderService = new PetOrderService();

const Orders = () => {
  const [petOrders, setPetOrders] = useState<IPetOrder[]>([]);
  const [petOrdersLoading, setPetOrdersLoading] = useState<boolean>(false);

  useEffect(() => {
    getPetOrders();
  }, []);

  const getPetOrders = async () => {
    setPetOrdersLoading(true);

    petOrderService
      .list({ expand: ["pet"] })
      .then(({ data }) => {
        console.log(data);
        setPetOrders(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setPetOrdersLoading(false));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pedidos - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box minH="100vh">
        {petOrdersLoading ? (
          <Spinner />
        ) : (
          <SlideFade
            in={!petOrdersLoading}
            style={{ width: "100%" }}
            offsetY={"20px"}
            transition={{ enter: { duration: 0.5 } }}
          >
            <DataTable
              columns={columns}
              data={petOrders}
              rowDetailPath={"orders"}
              rowDetailIdentifier={"_id"}
              defaultColumnVisibility={{
                _id: false,
              }}
            />
          </SlideFade>
        )}
      </Box>
    </>
  );
};
export default Orders;
