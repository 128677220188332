import { useEffect, useState } from "react";

import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import { ENV } from "../constants/environment.base";
import { ERROR_MESSAGES } from "../constants/error_messages";
import { AuthService } from "../services/auth.service";

const authService = new AuthService();

export const useAuth = () => {
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const handleAuthFormSubmit = () => {
    setLoading(true);

    var encrypted_pwd = encodeURI(
      CryptoJS.AES.encrypt(password, ENV.ENCRYPTION_KEY).toString()
    );

    authService
      .login(email, encrypted_pwd)
      .then(({ data }) => {
        console.log("data", data);
        localStorage.setItem("jwt", data.access_token);
        window.dispatchEvent(new Event("storage"));
      })
      .catch((err) => {
        console.error(err);
        setErrorMsg(
          ERROR_MESSAGES[err.response.data.code] || err.response.data.code
        );

        setLoading(false);
      });
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    loading,
    errorMsg,
    handleAuthFormSubmit,
  };
};
