import {
  Alert,
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Tag,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IDelivery, IPet } from "../types/types";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { NavLink as RouterNavLink } from "react-router-dom";
import Stripe from "stripe";
import { images } from "../assets/images/images";
import Spinner from "../components/Spinner";
import { ENV } from "../constants/environment.base";
import { DeliveryService } from "../services/delivery.service";
import { PublicDeliveriesInput } from "../types/db.schema";

const deliveryService = new DeliveryService();

const DeliveryDetail = () => {
  const toast = useToast();

  let { id } = useParams();

  const [delivery, setDelivery] = useState<IDelivery>();
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(false);

  const [dateDelivered, setDateDelivered] = useState<Date>(new Date());

  useEffect(() => {
    getDelivery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDelivery = async () => {
    if (!id) return;

    setDeliveryLoading(true);

    deliveryService
      .retrieve(id, { expand: ["pet"] })
      .then(({ data }) => {
        console.log(data);
        setDelivery(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setDeliveryLoading(false));
  };

  const encodeAddressObjectToUrl = (obj: Stripe.Address) => {
    return `${encodeURI(obj.line1 || "")},${encodeURI(obj.line2 || "")},${
      obj.postal_code
    }, ${obj.city}, ${obj.state}, ${obj.country}`;
  };

  const updateDelivery = (id: string, data: Partial<PublicDeliveriesInput>) => {
    deliveryService
      .update(id, { data }, { expand: ["pet"] })
      .then(({ data }) => {
        console.log(data);
        setDelivery(data);

        toast({
          position: "top",
          title: "Se han modificado los datos del envío correctamente",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        if (
          !moment(data.date_delivered).isSame(moment(delivery?.delivery_date))
        ) {
          toast({
            position: "top",
            title: "Fecha inesperada",
            description:
              "Has entregado en una fecha inesperada. Debes recalcular las fechas de los próximos envíos.",
            status: "warning",
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        toast({
          position: "top",
          title: "Ha ocurrido un error al actualizar el envío",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalle del envío - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box minH="100vh">
        {deliveryLoading ? (
          <Spinner />
        ) : delivery ? (
          <VStack
            w={"full"}
            spacing={8}
            alignItems={"start"}
            justifyContent={"start"}
            textAlign={"start"}
          >
            <HStack
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              spacing={4}
              w={"full"}
            >
              <Heading as={"h1"}>{(delivery.pet as IPet).name}</Heading>
              {(delivery.status === "pending" ||
                delivery.status === "preparing") &&
                delivery.payment && (
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={"sm"}
                        borderRadius={"lg"}
                        colorScheme={"secondary"}
                        variant={"outline"}
                      >
                        Marcar como entregado
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Fecha de entrega</PopoverHeader>
                      <PopoverBody>
                        <VStack spacing={4}>
                          <Input
                            placeholder="Fecha"
                            size="sm"
                            type="date"
                            lang="es-ES"
                            value={moment(dateDelivered).format("YYYY-MM-DD")}
                            onChange={(e) =>
                              setDateDelivered(new Date(e.target.value))
                            }
                          />

                          {dateDelivered > new Date() && (
                            <Text color={"red.500"}>
                              La fecha de entrega no puede ser posterior a la
                              fecha actual
                            </Text>
                          )}

                          <Button
                            size={"sm"}
                            w={"full"}
                            borderRadius={"lg"}
                            colorScheme={"brand"}
                            isDisabled={
                              !dateDelivered || dateDelivered > new Date()
                            }
                            onClick={() =>
                              updateDelivery(delivery._id, {
                                status: "delivered",
                                date_delivered: dateDelivered,
                              })
                            }
                          >
                            Enviar
                          </Button>
                        </VStack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
            </HStack>
            <VStack
              textAlign={"start"}
              w={"full"}
              alignItems={"start"}
              justifyContent={"start"}
              divider={<StackDivider color={"red.200"} />}
            >
              <VStack
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={2}
                w={"full"}
              >
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    ID
                  </Text>
                  <Text>{delivery._id}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Estado
                  </Text>
                  <Tag
                    size={"sm"}
                    variant="solid"
                    colorScheme={
                      ENV.DELIVERY_KEY_CAPTIONS[delivery.status].color
                    }
                  >
                    {ENV.DELIVERY_KEY_CAPTIONS[delivery.status].caption}
                  </Tag>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Peludo
                  </Text>
                  <Link
                    as={RouterNavLink}
                    to={`/pets/${(delivery.pet as IPet)._id}`}
                    color={"blue.700"}
                    fontWeight={"medium"}
                  >
                    {(delivery.pet as IPet).name}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Dirección
                  </Text>
                  <Link
                    as={RouterNavLink}
                    isExternal
                    to={`https://maps.google.com/?q=${encodeAddressObjectToUrl(
                      delivery.address
                    )}`}
                    color={"blue.700"}
                    fontWeight={"medium"}
                  >
                    {delivery.address.line1}, {delivery.address.line2} <br />
                    {delivery.address.postal_code} {delivery.address.city},{" "}
                    {delivery.address.state}, {delivery.address.country}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Fecha de entrega
                  </Text>
                  <Text>
                    {moment(delivery.delivery_date).format("DD/MM/YYYY")}
                  </Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Entregado el
                  </Text>
                  <Text>{delivery.date_delivered || "N/A"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Es el último del ciclo
                  </Text>
                  <Text>{delivery.is_last_in_cycle ? "Sí" : "No"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Es el segundo del ciclo
                  </Text>
                  <Text>{delivery.is_secondary ? "Sí" : "No"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Es transición
                  </Text>
                  <Text>{delivery.is_trial ? "Sí" : "No"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Usuario notificado
                  </Text>
                  <Text>{delivery.notification_sent ? "Sí" : "No"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Pago
                  </Text>
                  {delivery.payment ? (
                    <Link
                      as={RouterNavLink}
                      isExternal
                      to={`https://dashboard.stripe.com/payments/${delivery.payment}`}
                      color={"blue.700"}
                      fontWeight={"medium"}
                    >
                      {delivery.payment}
                      <ExternalLinkIcon mx="2px" />
                    </Link>
                  ) : (
                    <Text>N/A</Text>
                  )}
                </HStack>
                <VStack
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  spacing={2}
                  w={"full"}
                  divider={<StackDivider color={"gray.200"} />}
                  mt={8}
                >
                  <Heading as={"h2"} size={"md"}>
                    Contenido de la caja
                  </Heading>
                  <VStack
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    spacing={1}
                  >
                    <HStack alignItems={"flex-start"}>
                      <Text fontWeight={"bold"} minW={"200px"}>
                        Número de bolsas
                      </Text>
                      <Text>{delivery.content.bag_count}</Text>
                    </HStack>
                    <HStack alignItems={"flex-start"}>
                      <Text fontWeight={"bold"} minW={"200px"}>
                        Cajas
                      </Text>
                      <Text>{delivery.content.package_count}</Text>
                    </HStack>
                    <HStack alignItems={"flex-start"}>
                      <Text fontWeight={"bold"} minW={"200px"}>
                        Gramos diarios
                      </Text>
                      <Text>{delivery.content.total_daily_grams} gramos</Text>
                    </HStack>
                    <HStack alignItems={"flex-start"}>
                      <Text fontWeight={"bold"} minW={"200px"}>
                        Bolsas diarias
                      </Text>
                      <VStack spacing={1} alignItems={"flex-start"}>
                        {Object.keys(delivery.content.daily_bags).map((key) => (
                          <HStack key={key}>
                            <Text fontWeight={"semibold"}>{key} gramos:</Text>
                            <Text>
                              {
                                (
                                  delivery.content.daily_bags as {
                                    [key: string]: number;
                                  }
                                )[key]
                              }{" "}
                              bolsa(s)
                            </Text>
                          </HStack>
                        ))}
                      </VStack>
                    </HStack>
                    <HStack alignItems={"flex-start"}>
                      <Text fontWeight={"bold"} minW={"200px"}>
                        Menú
                      </Text>
                      <VStack spacing={1} alignItems={"flex-start"}>
                        <HStack>
                          <Image src={images.chicken} h={"6"} />
                          <Text fontWeight={"semibold"}>Pollo</Text>
                          <Text>{delivery.content.menu.chicken} envases</Text>
                        </HStack>
                        <HStack>
                          <Image src={images.beef} h={"6"} />
                          <Text fontWeight={"semibold"}>Ternera</Text>
                          <Text>{delivery.content.menu.beef} envases</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        ) : (
          <>
            <Alert>No se encontró el envío {id}</Alert>
          </>
        )}
      </Box>
    </>
  );
};

export default DeliveryDetail;
