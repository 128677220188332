import {
  Alert,
  Box,
  HStack,
  Heading,
  Link,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IPet, IUser } from "../types/types";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { NavLink as RouterNavLink } from "react-router-dom";
import Stripe from "stripe";
import Spinner from "../components/Spinner";
import { ENV } from "../constants/environment.base";
import { PetService } from "../services/pet.service";

const petService = new PetService();

const PetDetail = () => {
  let { id } = useParams();

  const [pet, setPet] = useState<IPet>();
  const [petLoading, setPetLoading] = useState<boolean>(false);

  useEffect(() => {
    getPet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPet = async () => {
    if (!id) return;

    setPetLoading(true);

    petService
      .retrieve(id, { expand: ["user"] })
      .then(({ data }) => {
        console.log(data);
        setPet(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setPetLoading(false));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalle del peludo - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box minH="100vh">
        {petLoading ? (
          <Spinner />
        ) : pet ? (
          <VStack
            w={"full"}
            spacing={8}
            alignItems={"start"}
            justifyContent={"start"}
            textAlign={"start"}
          >
            <Heading as={"h1"}>{pet.name}</Heading>
            <VStack
              textAlign={"start"}
              w={"full"}
              alignItems={"start"}
              justifyContent={"start"}
              divider={<StackDivider color={"red.200"} />}
            >
              <VStack
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={2}
              >
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    ID
                  </Text>
                  <Text>{pet._id}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Usuario
                  </Text>
                  <Link
                    isExternal
                    as={RouterNavLink}
                    to={`https://dashboard.stripe.com/customers/${
                      ((pet.user as IUser).stripe as Stripe.Customer).id
                    }`}
                    color={"blue.700"}
                    fontWeight={"medium"}
                  >
                    {((pet.user as IUser).stripe as Stripe.Customer).name ||
                      ((pet.user as IUser).stripe as Stripe.Customer).email}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Peso
                  </Text>
                  <Text>{pet.weight} Kg</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Edad
                  </Text>
                  <Text>{pet.age_group}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Actividad
                  </Text>
                  <Text>{pet.activity_level}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Raza
                  </Text>
                  <Text>
                    {
                      ENV.DOG_BREEDS[
                        ENV.DOG_BREEDS.findIndex(
                          (b) => b.code === pet.breed_code
                        )
                      ].name
                    }
                  </Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Género
                  </Text>
                  <Text>{pet.gender}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Esterilizado
                  </Text>
                  <Text>{pet.sterilized ? "Sí" : "No"}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Enfermedades
                  </Text>
                  <Text>{pet.illness}</Text>
                </HStack>
                <HStack alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} minW={"200px"}>
                    Ingesta diaria
                  </Text>
                  <Text>{pet.daily_grams}</Text>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        ) : (
          <>
            <Alert>No se encontró el peludo {id}</Alert>
          </>
        )}
      </Box>
    </>
  );
};

export default PetDetail;
