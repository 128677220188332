import axios, { AxiosResponse } from "axios";
import { IMenuIngredient, IStockItem } from "../types/types";

import { ENV } from "../constants/environment.base";
import { IPriority } from "../hooks/useKitchen";

export class AdminService {
  private readonly API_ROUTE_PREFIX: string = "";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ADMIN_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ADMIN_ENDPOINT;

  /**
   * Get stock
   *
   * @returns Promise with stock fetch response
   */
  public stock: () => Promise<AxiosResponse<IStockItem[]>> = () => {
    return axios.get(`${this.API_PREFIX}/stock`);
  };

  /**
   * Update stock
   *
   * @returns Promise with stock update response
   */
  public stock_update: (data: {
    item: string;
    stock: number;
    type: "suppliers" | "cooking" | "packing" | "shipping" | "calc";
    kind: string;
  }) => Promise<AxiosResponse<IStockItem>> = (data) => {
    return axios.post(`${this.API_PREFIX}/stock`, {
      ...data,
    });
  };

  /**
   * Get menus
   *
   * @returns Promise with menus fetch response
   */
  public menus: () => Promise<AxiosResponse<IMenuIngredient[]>> = () => {
    return axios.get(`${this.API_PREFIX}/menus`);
  };

  /**
   * Update menus
   *
   * @returns Promise with menus update response
   */
  public menus_update: (
    data: IMenuIngredient
  ) => Promise<AxiosResponse<IMenuIngredient>> = (data) => {
    return axios.post(`${this.API_PREFIX}/menus`, {
      ...data,
    });
  };

  /**
   * Get cooking quantities
   *
   * @returns Promise with cooking quantities fetch response
   */
  public cooking_quantities: () => Promise<
    AxiosResponse<{
      cooking_quantities: {
        [menu: string]: IPriority;
      };
    }>
  > = () => {
    return axios.get(`${this.API_PREFIX}/kitchen/cooking-quantities`);
  };

  /**
   * Get packing quantities
   *
   * @returns Promise with packing quantities fetch response
   */
  public packing_quantities: () => Promise<
    AxiosResponse<{
      packing_quantities: {
        [menu: string]: { [grams: string]: IPriority };
      };
    }>
  > = () => {
    return axios.get(`${this.API_PREFIX}/kitchen/packing-quantities`);
  };
}
