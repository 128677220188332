import { Navigate, Route, Routes } from "react-router-dom";

import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Menus from "./Menus";

const Utils = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Utils - Candi Management</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box minH="100vh">
        <Routes>
          <Route path="menus" element={<Menus />} />
          <Route path="*" element={<Navigate to="/utils/menus" />} />
        </Routes>
      </Box>
    </>
  );
};

export default Utils;
